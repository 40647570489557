<template>
  <v-data-table
    :headers="headers"
    :items="mails"
    :items-per-page="5"
    class="elevation-1 "
  >
    <template v-slot:item.actions="{ item }">
      <v-btn
        v-on="on"
        icon
        @click="
          $router.push({
            name: 'appointmentView',
            params: { id: item.appointment_id },
          })
        "
        ><v-icon small>$eye</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "MailList",
  data() {
    return {
      mails: [],
      headers: [
        { text: "Cliente", value: "appointment.customer.user.fullname" },
        { text: "Email", value: "mail" },
        { text: "Tipo", value: "type" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchMails();
  },
  methods: {
    ...mapActions("appointments", ["getAllMails"]),
    fetchMails() {
      this.getAllMails({
        pagination: null,
        filters: {
          mail_target: "EMAIL",
        },
      }).then((mails) => {
        this.mails = mails.data;
        console.log("MAILS FETCHED", this.mails);
      });
    },
  },
};
</script>
